import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";

const Contact = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/contact");
      setData(response.data[0]);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h4yo129",
        "template_ujqaitm",
        form.current,
        "WeMbN4S0ZdScghV91"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);
  return (
    <>
      <Layout>
        <ToastContainer />

        <section
          className="title-hero-bg "
          data-stellar-background-ratio="0.2"
          loading="lazy"
        >
          {data && (
            <img
              src={`https://idare.konakglobal.com/uploads/${data.img}`}
            ></img>
          )}
          <div className="container">
            <div className="page-title text-center">
              <h1>{strings["Contact"]}</h1>
            </div>
          </div>
        </section>
        <div className="col-sm-12 section-heading section-heading-start pt-10">
          <h2 className="wow fadeTop text-uppercase" data-wow-delay="0.1s">
            {" "}
            {strings["Contact Us"]}{" "}
          </h2>
          <h4
            class=" text-center 
                     source-font wow fadeTop padding-res-30 "
            data-wow-delay="0.2s"
          >
            {strings["WE ARE HERE FOR YOU"]}
          </h4>
        </div>
        <section className="contact-us pb-100" style={{paddingTop:"5vh"}}>
          <div className="container">
            <div className="row center_row_contact res_contact_center">
              <div className="col-md-8 col-sm-12 col-xs-12 wow fadeRight">
                <form
                  onSubmit={sendEmail}
                  ref={form}
                  name="contact-form"
                  id="contact-form"
                  action="php/contact.php"
                  method="POST"
                >
                  <div className="messages" />
                  <div className="form-group">
                    <label className="sr-only" htmlFor="name"></label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required="required"
                      placeholder={strings["Your Name"]}
                      data-error="Your Name is Required"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="email"></label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder={strings["Your Email"]}
                      required="required"
                      data-error="Please Enter Valid Email"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="number"></label>
                    <input
                      type="number"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder={strings["Your Phone"]}
                      required="required"
                      data-error="Please Enter Valid Email"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="subject"></label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      id="subject"
                      placeholder={strings["Your Subject"]}
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="message"></label>
                    <textarea
                      name="message"
                      className="form-control"
                      id="message"
                      rows={7}
                      placeholder={strings["Your Message"]}
                      required
                      data-error="Please, Leave us a message"
                      defaultValue={""}
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-color btn-circle"
                  >
                    {strings["Send Mail"]}
                  </button>
                </form>
              </div>
              <div className="align-center">
                <div className="col-md-8 col-sm-12 col-xs-12  wow fadeLeft contact_second_row">
                  <h3 className=""> {strings["adres"]}</h3>
                  <address>
                    {data &&
                      ReactHtmlParser(
                        currentLanguageCode === "tr"
                          ? data.adres
                          : data.adres_en
                      )}
                  
                  </address>
                  <div className="d-grid" style={{marginBottom:"20px"}}>  <a  style={{marginBottom:"20px"}} href="mailto:info@konakglobal.com">
                   
                    {data && ReactHtmlParser(data.mail)}
                  </a>
                  <a  style={{marginBottom:"20px"}} href={`tel:${data && data.tel1}`}>
                    {data && ReactHtmlParser(data.tel1)}
                  </a>
                  <a href={`tel:${data && data.tel2}`}>
                    {data && ReactHtmlParser(data.tel2)}
                  </a></div>
                
                  <h3 className=""> {strings["working-hours"]}</h3>
                  <p className="res-p-pb">
                    {" "}
                    <span>
                      {" "}
                      {data &&
                        ReactHtmlParser(
                          currentLanguageCode === "tr"
                            ? data.saat
                            : data.saat_en
                        )}
                    </span>{" "}
                    <br />
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61557336752553"
                    >
                      <FaFacebookF
                        style={{ marginRight: "15px" }}
                        color="black"
                      />{" "}
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/konak.global/"
                    >
                      <FaInstagram
                        style={{ marginRight: "15px" }}
                        color="black"
                      />{" "}
                    </a>
                    <a target="_blank" href="https://twitter.com/konakglobal">
                      <BsTwitterX
                        style={{ marginRight: "15px" }}
                        color="black"
                      />{" "}
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@KonakGlobal"
                    >
                      <FaYoutube
                        style={{ marginRight: "15px" }}
                        color="black"
                      />{" "}
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/konak-global/"
                    >
                      <FaLinkedin
                        style={{ marginRight: "15px" }}
                        color="black"
                      />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*=== Contact Us End ======*/}
        {/*=== Google Maps Start ======*/}
        <section className="pt-0 pb-0 map-section">
          <div id="myMap" className="wide">
            <iframe
              className="contact_map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d765.1410821810078!2d32.8123422!3d39.9063856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f829ec97cdf%3A0x925a0a1e24686d17!2sKonak%20Global!5e0!3m2!1str!2str!4v1718269388509!5m2!1str!2str"
              allowfullscreen
              loading="lazy"
              width="100%"
              style={{ height: "50rem" }}
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </Layout>
    </>
  );
};

Contact.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(Contact));
