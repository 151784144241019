import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";

const Footer = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/contact");
      setData(response.data[0]);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      {/*=== Footer Start ======*/}
      <footer className="footer" id="footer-fixed">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="widget widget-text">
                  <div className="logo logo-footer">
                    <Link to="/">
                      {" "}
                      <img
                        className="logo logo-display"
                        src="assets/img/logo/konak_icon_beyaz.png"
                        alt="logo"
                      />
                    </Link>{" "}
                  </div>
                  <p className="d-flex">
                    {/* {strings['footer-description']} */}
                    <i
                      className="icofont icofont-google-map"
                      style={{ paddingRight: "10px" }}
                    />{" "}
                    {data &&
                      ReactHtmlParser(
                        currentLanguageCode === "tr"
                          ? data.adres
                          : data.adres_en
                      )}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="widget widget-links">
                  <h5 className="widget-title">{strings["Corporate"]}</h5>
                  <ul>
                    <li>
                      <Link to="/">{strings["home"]}</Link>
                    </li>
                    <li>
                      <Link to="/about-us">{strings["About"]}</Link>
                    </li>
                    <li>
                      <Link to="/services">{strings["Services"]}</Link>
                    </li>
                    <li>
                      <Link to="/international-trade">
                        {strings["International Trade"]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/career">{strings["Career"]}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{strings["Contact"]}</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-6 col-md-4">
                <div className="widget widget-text widget-links">
                  <h5 className="widget-title">{strings["Contact Us"]}</h5>
                  <ul>
                    <li>
                      {" "}
                      <i className="icofont icofont-iphone" />{" "}
                      <a href={`tel:${data && data.tel1}`}>
                        {data && ReactHtmlParser(data.tel1)}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <i className="icofont icofont-fax" />{" "}
                      <a href={`tel:${data && data.tel2}`}>
                        {data && ReactHtmlParser(data.tel2)}
                      </a>
                    </li>
                    <li className="d-flex">
                      {" "}
                      <i className="icofont icofont-mail" />{" "}
                      <a href={`mailto:${data && data.mail}`}>
                        {data && ReactHtmlParser(data.mail)}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <ul className="social-media">
                  {/* <li>
                      <a href="#" className="icofont icofont-linkedin" />
                    </li>
                    <li>
                      <a href="#" className="icofont icofont-facebook" />
                    </li>
                    <li>
                      <a href="#" className="icofont icofont-twitter" />
                    </li>
                  
                    <li>
                      <a href="#" className="icofont icofont-instagram" />
                    </li> */}
                </ul>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="copy-right text-right">
                  © 2023 Konak Global. All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*=== Footer End ======*/}
      {/*=== GO TO TOP  ===*/}
      <a href="#" id="back-to-top" title="Back to top">
        ↑
      </a>
      {/*=== GO TO TOP End ===*/}
    </>
  );
};

Footer.propTypes = {
  children: PropTypes.any,
  strings: PropTypes.object,
};

export default multilanguage(Footer);
