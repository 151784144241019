import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import axios from "axios";

const Home = ({ currentLanguageCode, strings }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/homepage");
      setData(response.data[0]); 
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  if (!data) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return (
    <>
      <Helmet>
        <title>Konak Global</title>
        <meta name="description" content="Page Description" />
      </Helmet>
      <Layout path={window.location.pathname}>
        <div>
          {/*=== Flex Slider Start ======*/}
          <section className="pt-0 pb-0">
            <video
              className="d-block rounded home_video computer"
              width="100%"  
              height="100%"
              autoPlay={true}
              loop
              src={currentLanguageCode === "en" 
                ? `https://idare.konakglobal.com/uploads/${data.video_en}` 
                : `https://idare.konakglobal.com/uploads/${data.video}`}
              muted
              playsInline
            >
            </video>
            <video
              className="d-block rounded home_video mobile"
              width="100%"
              height="100%"
              autoPlay={true}
              loop
              src={currentLanguageCode === "en" 
                ? `https://idare.konakglobal.com/uploads/${data.video_mobil_en}` 
                : `https://idare.konakglobal.com/uploads/${data.video_mobil}`}
              muted
              playsInline
              loading="lazy"
            >
            </video>
            <div className="overlay">
              <p className="copyright">© Copyright Konak Global 2023</p>
              <div className="container">
                <div className="row">
                  
                  <div className="col-md-7">
                    <h1 className="home-video-title">
                      {ReactHtmlParser(currentLanguageCode === 'en' ? data.title_en : data.title)}
                    </h1>
                  </div>
                  {/* TO DO 
                  -- Services page load will fixed both tr-eng 
                  -- home page copyright overflows will fix  
                  -- home page text broken at some px will fix  both tr-eng
                  -- header-navbar squize when scroll down on   phones
                  -- services page images*/}
                </div>
              </div>
            </div>
          </section>

        </div>

      </Layout>
    </>
  );
};

export default connect()(multilanguage(Home));
