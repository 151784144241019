import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import axios from "axios";

const Services = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false); // Yeni state eklendi

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://idare.konakglobal.com/services"
      );
      setData(response.data);
    } catch (error) {
      // console.error("Error fetching data:", error);
      setError(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);
  return (
    <>
      <Layout>
        <>
          <section
            className="title-hero-bg "
            data-stellar-background-ratio="0.2"
            loading="lazy"
          >
          {error ? (
            <div className="text-center">
              <h2>Görsel bulunamadı</h2>
            </div>
          ) : (
            <div>
           
              {data.length > 0 && (
                <img
                  src={`https://idare.konakglobal.com/uploads/${data[0].services_img}`}
                  alt={data[0].services_maintitle}
                />
              )}
            </div>
          )}
            <div className="container">
              <div className="page-title text-center white-color">
                <h1>{strings["Service"]}</h1>
              </div>
            </div>
          </section>
          <section className="first-ico-box pb-0">
            <div className="container">
              {error ? (
                <div className="text-center">
                  <h2>İçerik bulunamadı</h2>
                </div>
              ) : (
                data.map((service) => (
                  <div
                    key={service.services_id}
                    className="row d-flex justify-content-center"
                  >
                    <div
                      className="col-md-12 col-xs-12 section-heading"
                      style={{ marginBottom: "5vh" }}
                    >
                      <h2 className="text-uppercase text-align-center">
                        {ReactHtmlParser(
                          currentLanguageCode === "tr"
                            ? service.services_maintitle
                            : service.services_maintitle_en
                        )}
                      </h2>
                      <div className="col-inner pb-0 spacer icon-font">
                        <div className="wow fadeTop">
                          <p className="text-align-center">
                            {ReactHtmlParser(
                              currentLanguageCode === "tr"
                                ? service.services_desc
                                : service.services_desc_en
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="mt-1 pb-50 services-inner">
                        {service.services.map((item) => (
                          <div
                            key={item.services_no}
                            className="col-lg-6 col-md-6 col-6 col-xs-12  d-flex justify-content-center align-items-center"
                            style={{ flexDirection: "column" }}
                          >
                            <h4 className="text-uppercase text-align-center">
                              {ReactHtmlParser(
                                currentLanguageCode === "tr"
                                  ? item.services_title
                                  : item.services_title_en
                              )}
                            </h4>{" "}
                            <img
                              src={
                                currentLanguageCode === "tr"
                                  ? `https://idare.konakglobal.com/uploads/${item.img}`
                                  : `https://idare.konakglobal.com/uploads/${item.img_en}`
                              }
                              alt={
                                currentLanguageCode === "tr"
                                  ? item.services_title
                                  : item.services_title_en
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </section>
        </>
      </Layout>
    </>
  );
};

Services.propTypes = {
  children: PropTypes.any,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Services));
