import Layout from "../Layout/Layout";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { VectorMap } from "react-jvectormap";
import CountryList from "../Components/CountryList";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import axios from "axios";

const mapData = {
  CA: 4000,
  US: 4000,
  SM: 6000,
  DZ: 6000,
  TN: 6000,
  LY: 6000,
  EG: 6000,
  ET: 6000,
  CL: 1000,
  BE: 5000,
  BA: 5000,
  BG: 5000,
  BY: 5000,
  LV: 5000,
  RS: 5000,
  AL: 5000,
  ME: 5000,
  HR: 5000,
  CY: 5000,
  CZ: 5000,
  DK: 5000,
  EE: 5000,
  Fİ: 5000,
  FR: 5000,
  IS:5000,
  DE: 5000,
  GB: 5000,
  GR: 5000,
  FI: 5000,
  NL: 5000,
  HU: 5000,
  IE: 5000,
  XK: 5000,
  MK: 5000,
  MD: 5000,
  PL: 5000,
  RO: 5000,
  XS: 6000,
  SK: 5000,
  SI: 5000,
  ES: 5000,
  CH: 5000,
  SE: 5000,
  UA: 5000,
  AF: 3000,
  AM: 3000,
  TJ:3000,
  AZ: 3000,
  CN: 3000,
  GE: 3000,
  IN: 3000,
  IR: 3000,
  IQ: 3000,
  JO: 3000,
  KZ: 3000,
  KG: 3000,
  LB: 3000,
  MN: 3000,
  PK: 3000,
  SY: 3000,
  TM: 3000,
  UZ: 3000,
  AU: 7000,
  RU: 5000,
  TR:1000,
};
const UniversalPage = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/ticaret");
      setData(response.data[0]); 
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  const handleClick = (e, countryCode) => {};

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);
  return (
    <>
      <Layout>
        <>
          <section
            className="title-hero-bg"
            data-stellar-background-ratio="0.2"
            loading="lazy"
          >
               {data &&
                        <img
                        src={`https://idare.konakglobal.com/uploads/${data.img}`}
                      ></img>}
            <div className="container">
              <div className="page-title text-center white-color">
                <h1>{strings["International Trade"]}</h1>
              </div>
            </div>
          </section>

          <section className="first-ico-box pb-0 pt-30">
            <div className="container">
              <div className="row">
                <div className="col-sm-10 section-heading">
                  <h2 className="">                  {data && ReactHtmlParser(currentLanguageCode === 'tr' ? data.title : data.title_en)}
</h2>
                  <h4
                    class=" text-center 
                     source-font wow fadeTop"
                    data-wow-delay="0.2s"
                  >
                                     {data && ReactHtmlParser(currentLanguageCode === 'tr' ? data.desc : data.desc_en)}

                  </h4>
                </div>
              </div>
            </div>
          </section>

          <div className="map_int_inner">
            <div className="row">
              {" "}
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <VectorMap
                  markerStyle={{
                    initial: {
                      fill: "#4DAC26",
                    },
                  }}
                  className="vectorMap"
                  map={"world_mill"}
                  backgroundColor="#ffffff" //change it to ocean blue: #0077be
                  zoomOnScroll={false}
                  containerStyle={{
                    width: "100%",
                    height: "80vh",
                  }}
                  
                  containerClassName="map"
                  regionStyle={{
                    initial: {
                      fill: "#888",
                      "fill-opacity": 1,
                      stroke: "none",
                      "stroke-width": 1,
                      "stroke-opacity": 0,
                    },
                    hover: {
                      "fill-opacity": 0.6,
                      cursor: "pointer",
                    },
                  }}
                  series={{
                    regions: [
                      {
                        values: mapData, //this is your data
                        scale: [
                          "#E21818",
                          "#0B2447",
                          "#A5D7E8",
                          "#19376D",
                          "#FFD617",
                          "#FBFFB1",
                          "#34a125",
                        ], //your color game's here
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <div className="country-list">
            <CountryList />
          </div>
        </>
      </Layout>
    </>
  );
};

UniversalPage.propTypes = {
  children: PropTypes.any,
  strings: PropTypes.object,
};

export default connect()(multilanguage(UniversalPage));
