import Layout from "../Layout/Layout";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import React, { useEffect, useState } from "react";
import axios from "axios";

const AboutUs = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/about");
      setData(response.data[0]);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);
  return (
    <>
      <Layout>
        <>
          <section
            className="title-hero-bg"
            data-stellar-background-ratio="0.2"
            loading="lazy"
          >
            {data &&
                        <img
                        src={`https://idare.konakglobal.com/uploads/${data.img}`}
                      ></img>}
            
            <div className="container">
              <div className="page-title text-center white-color">
                <h1>{strings["About"]}</h1>
              </div>
            </div>
          </section>

          <section className="first-ico-box">
            <div className="container">
              <div className="row">
                <div className="col-sm-10 section-heading">
                  <h2 className="text-uppercase">{strings["Who We Are"]}</h2>
                  <div className="mt-30">
                    <p>
                      {data &&
                        ReactHtmlParser(
                          currentLanguageCode === "tr"
                            ? data.biz_kimiz_tr
                            : data.biz_kimiz_en
                        )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row pt-50">
                <div className="col-sm-10 section-heading">
                  <h2 className="text-uppercase"> {strings["Basic Values"]}</h2>
                </div>
              </div>
              <div
                className="row mt-50 icon-font"
                style={{ marginBottom: "5rem" }}
              >
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="/assets/img/icon/sorumluluk.png"
                    alt="sorumluluk"
                    className="img-responsive-50"
                  />
                  <p>{strings["about-icon-one"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/assets/img/icon/iyi_bir_hizmet.png"
                    alt="iyi bir hizmet"
                    className="img-responsive-50"
                  />
                  <p>{strings["about-icon-two"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/degisim.png"
                    className="img-responsive-50"
                    alt="değişim"
                  />
                  <p>{strings["about-icon-three"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/sira_disi.png"
                    className="img-responsive-50"
                    alt="sıra dışı"
                  />
                  <p>{strings["about-icon-four"]}</p>
                </div>

                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="/assets/img/icon/ogrenme.png"
                    className="img-responsive-50"
                    alt="oğrenme"
                  />
                  <p>{strings["about-icon-five"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/assets/img/icon/gelisim.png"
                    className="img-responsive-50"
                    alt="gelişim"
                  />
                  <p>{strings["about-icon-six"]}</p>
                </div>
              </div>
              <div className="row mt-50 icon-font pb-50">
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/iletisim.png"
                    className="img-responsive-50"
                    alt="iletişim"
                  />
                  <p>{strings["about-icon-seven"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/ekip.png"
                    className="img-responsive-50"
                    alt="ekip"
                  />
                  <p>{strings["about-icon-eight"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.1s"
                >
                  <img
                    src="/assets/img/icon/daha_az_ile_daha_cok.png"
                    className="img-responsive-50"
                    alt="daha az ile daha çok"
                  />
                  <p>{strings["about-icon-nine"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="/assets/img/icon/tutku.png"
                    className="img-responsive-50"
                    alt="tutku"
                  />
                  <p>{strings["about-icon-ten"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/alcak_gonulluluk.png"
                    className="img-responsive-50"
                    alt="alçak gönüllülük"
                  />
                  <p>{strings["about-icon-eleven"]}</p>
                </div>
                <div
                  className="col-md-2 col-sm-6 feature-box text-center radius-icon wow fadeTop"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="/assets/img/icon/sadakat.png"
                    className="img-responsive-50"
                    alt="sadakat"
                  />
                  <p>{strings["about-icon-twelve"]}</p>
                </div>
              </div>
              <div className="container pt-50 pb-50">
                <div className="row icon-font">
                  <div className="col-md-6 col-sm-12">
                    <div className=" section-heading">
                      <h2 className="text-uppercase"> {strings["OUR"]}</h2>
                    </div>
                    <p className="mt-20">
                      {data &&
                        ReactHtmlParser(
                          currentLanguageCode === "tr"
                            ? data.misyon_tr
                            : data.misyon_en
                        )}
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="section-heading">
                      <h2 className="text-uppercase"> {strings["VISIONS"]}</h2>
                    </div>
                    <p className="mt-20">
                      {data &&
                        ReactHtmlParser(
                          currentLanguageCode === "tr"
                            ? data.vizyon_tr
                            : data.vizyon_en
                        )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </Layout>
    </>
  );
};

AboutUs.propTypes = {
  children: PropTypes.any,
  strings: PropTypes.object,
};

export default multilanguage(AboutUs);
