import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { multilanguage, changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const Header = ({
  headerClassName,
  strings,
  currentLanguageCode,
  dispatch,
}) => {
  return (
    <>
      {/*=== Header Start ======*/}
      <nav
        style={{
          minHeight: "5vh",
          /* From https://css.glass */
          background: " rgba(198, 149, 51, 0.25)",
          boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
          backdropFilter: " blur(1px)",
          WebkitBackdropFilter: " blur(1px)",
          border: " 1px solid rgba(255, 255, 255, 0.28)",
        }}
        className={headerClassName}
      >
        {/*=== Start Top Search ===*/}
        <div className="fullscreen-search-overlay" id="search-overlay">
          {" "}
          <a href="#" className="fullscreen-close" id="fullscreen-close-button">
            <i className="icofont icofont-close" />
          </a>
          <div id="fullscreen-search-wrapper">
            <form method="get" id="fullscreen-searchform">
              <input
                type="text"
                defaultValue
                placeholder="Type and hit Enter..."
                id="fullscreen-search-input"
                className="search-bar-top"
              />
              <i className="icofont icofont-search-1 fullscreen-search-icon">
                <input defaultValue type="submit" />
              </i>
            </form>
          </div>
        </div>
        {/*=== End Top Search ===*/}
        <div className="container">
          {/*=== Start Atribute Navigation ===*/}
          <div className="attr-nav">
            <ul className="social-media-dark social-top">
              {/* <li>
                <a href="#" className="icofont icofont-facebook" />
              </li>
              <li>
                <a href="#" className="icofont icofont-twitter" />
              </li>
              <li>
                <a href="#" className="icofont icofont-linkedin" />
              </li> */}
              <li>
                <button
                  className="language"
                  onClick={(e) => {
                    e.preventDefault();

                    if (currentLanguageCode == "tr") {
                      dispatch(changeLanguage("en"));
                    } else {
                      dispatch(changeLanguage("tr"));
                    }
                  }}
                >
                  <i className="icofont icofont-globe" />
                  {currentLanguageCode == "tr" ? "EN" : "TR"}
                </button>
              </li>
            </ul>
          </div>
          {/*=== End Atribute Navigation ===*/}
          {/*=== Start Header Navigation ===*/}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              {" "}
              <i className="icofont icofont-navigation-menu" />{" "}
            </button>
            <div className="logo">
              {" "}
              <NavLink to="/">
                {" "}
                <img
                  className="logo logo-display"
                  src="assets/img/logo/konak_icon_beyaz.png"
                  alt="logo"
                />{" "}
                <img
                  className="logo logo-scrolled"
                  src="assets/img/logo/konak_icon_beyaz.png"
                  alt="siyah logo"
                />{" "}
              </NavLink>{" "}
            </div>
          </div>
          {/*=== End Header Navigation ===*/}
          {/*=== Collect the nav links, forms, and other content for toggling ===*/}
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav" data-in="fadeIn" data-out="fadeOut">
              <li className="dropdown">
                {" "}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-color" : ""
                  }
                  to="/about-us"
                >
                  {/* {strings['About']} */}
                  {strings["About"]}
                </NavLink>
              </li>

              <li className="dropdown">
                {" "}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-color" : ""
                  }
                  to="/services"
                >
                  {strings["Services"]}
                </NavLink>{" "}
              </li>

              <li className="dropdown">
                {" "}
                <NavLink
                  to="/international-trade"
                  className={(navData) =>
                    navData.isActive ? "active-color" : ""
                  }
                >
                  {strings["International Trade"]}
                </NavLink>
              </li>

              <li className="dropdown">
                {" "}
                <NavLink
                  to="/career"
                  className={(navData) =>
                    navData.isActive ? "active-color" : ""
                  }
                >
                  {strings["Career"]}
                </NavLink>
              </li>

              <li className="dropdown">
                {" "}
                <NavLink
                  to="/contact"
                  className={(navData) =>
                    navData.isActive ? "active-color" : ""
                  }
                >
                  {strings["Contact"]}
                </NavLink>
              </li>
            </ul>
          </div>
          {/*=== /.navbar-collapse ===*/}
        </div>
      </nav>
      {/*=== Header End ======*/}
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  children: PropTypes.any,
  strings: PropTypes.object,
};

export default connect()(multilanguage(Header));
