import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Accordion from "react-bootstrap/Accordion";

const Career = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState(null);
  const [evetSecildi, setEvetSecildi] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/career");
      setData(response.data[0]);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setEvetSecildi(event.target.value === "evet");
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g0vhpjl",
        "template_r99wfgg",
        form.current,
        "cU9yQx8Mv4GYvWp38"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Layout>
        <ToastContainer />
        <section
          className="title-hero-bg"
          data-stellar-background-ratio="0.2"
          loading="lazy"
        >
          {data && (
            <img
              src={`https://idare.konakglobal.com/uploads/${data.img}`}
            ></img>
          )}
          <div className="container">
            <div className="page-title text-center">
              <h1>{strings["Careers"]}</h1>
            </div>
          </div>
        </section>

        <div className="col-sm-12 section-heading section-heading-start pt-10 ">
          <h2 className="wow fadeTop text-uppercase" data-wow-delay="0.1s">
            {data &&
              ReactHtmlParser(
                currentLanguageCode === "tr" ? data.title_tr : data.title_en
              )}
          </h2>
          <h4
            className="text-center source-font wow fadeTop padding-res-30"
            data-wow-delay="0.2s"
          >
            {data &&
              ReactHtmlParser(
                currentLanguageCode === "tr" ? data.desc_tr : data.desc_en
              )}
          </h4>
        </div>

        <section className="career pb-100" style={{paddingTop:"5vh"}}>
          <div className="container">
            <div className="row center_row_contact d-flex justify-content-center">
              <div className="col-md-8 col-sm-12 col-xs-12">
                <form
                  className="d-flex flex-column"
                  onSubmit={sendEmail}
                  ref={form}
                  name="contact-form"
                  id="contact-form"
                  action="php/contact.php"
                  method="POST"
                >
                  <div className="messages" />
                  <div className="form-group">
                    <label className="sr-only" htmlFor="name"></label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      required="required"
                      placeholder={strings["name"]}
                      data-error="Your Name is Required"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="surname"></label>
                    <input
                      type="text"
                      name="surname"
                      className="form-control"
                      id="surname"
                      required="required"
                      placeholder={strings["surname"]}
                      data-error="Your Surname is Required"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="email"></label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder={strings["email"]}
                      required="required"
                      data-error="Please Enter Valid Email"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="gender"></label>
                    <select
                      style={{ color: "#888" }}
                      id="gender"
                      name="gender"
                      className="form-control pb0"
                    >
                      <option value="" selected disabled>
                        {strings["gender"]}
                      </option>
                      <option value="male">{strings["man"]}</option>
                      <option value="female">{strings["woman"]}</option>
                    </select>
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="birth"></label>
                    <input
                      style={{ color: "#888" }}
                      type="date"
                      name="birth"
                      className="form-control"
                      id="birth"
                      placeholder={strings["date-of-birth"]}
                      required="required"
                      data-error="Please Enter Date of Birth"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="city"></label>
                    <input
                      type="city"
                      name="city"
                      className="form-control"
                      id="city"
                      placeholder={strings["country-where-you-live"]}
                      required="required"
                      data-error="Please Enter City you live in"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="email"></label>
                    <input
                      type="number"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder={strings["Your Phone"]}
                      required="required"
                      data-error="Please Enter Phone Number"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="education"></label>
                    <input
                      type="text"
                      name="education"
                      className="form-control"
                      id="education"
                      placeholder={strings["education"]}
                      required="required"
                      data-error="Please Enter Educational Status"
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <div className="form-group radio-buttons">
                    <label
                      className="sr-only"
                      htmlFor="recent-work-experience"
                    ></label>
                    <input
                      type="radio"
                      name="evethayir"
                      value="evet"
                      onChange={handleRadioChange}
                      className="form-control evethayir"
                      id="recent-work-experience"
                      placeholder={strings["recent-work-experience"]}
                      required="required"
                      data-error="Please Enter Educational Status"
                    />{" "}
                    <span className="design-center">
                      {" "}
                      {strings["i-have-work-experience"]}
                    </span>
                    <input
                      type="radio"
                      name="evethayir"
                      value="hayır"
                      onChange={handleRadioChange}
                      className="form-control evethayir"
                      id="recent-work-experience"
                      placeholder={strings["recent-work-experience"]}
                      required="required"
                      data-error="Please Enter Educational Status"
                    />
                    <span className="design-center">
                      {strings["i-have-no-work-experience"]}
                    </span>
                    <div className="help-block with-errors mt-20" />
                  </div>
                  {evetSecildi && (
                    <span>
                      <div className="form-group">
                        <label className="sr-only" htmlFor="message"></label>
                        <textarea
                          name="job-title"
                          className="form-control"
                          id="job-title"
                          placeholder={strings["job-title"]}
                          required
                          data-error="Please, Leave us a message"
                          defaultValue={""}
                        />
                        <div className="help-block with-errors mt-20" />
                      </div>
                      <div className="form-group">
                        <label className="sr-only" htmlFor="message"></label>
                        <textarea
                          name="company"
                          className="form-control"
                          id="company"
                          placeholder={strings["company"]}
                          required
                          data-error="Please, Leave us a message"
                          defaultValue={""}
                        />
                        <div className="help-block with-errors mt-20" />
                      </div>
                    </span>
                  )}
                  <div className="form-group">
                    <label className="sr-only" htmlFor="subject"></label>
                    <input
                      type="text"
                      name="position"
                      className="form-control"
                      id="position"
                      placeholder={strings["Position You Want to Apply For"]}
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only" htmlFor="message"></label>
                    <textarea
                      name="message"
                      className="form-control"
                      id="message"
                      rows={7}
                      placeholder={strings["Cover Letter"]}
                      required
                      data-error="Please, Leave us a message"
                      defaultValue={""}
                    />
                    <div className="help-block with-errors mt-20" />
                  </div>
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-color btn-circle"
                  >
                    {strings["Apply"]}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="open-jobs">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 col-sm-12 col-xs-12 d-flex flex-column justify-content-center align-items-center">
                <h2>
                  {ReactHtmlParser(
                    currentLanguageCode === "tr"
                      ? data.openjobs_title_tr
                      : data.openjobs_title_en
                  )}
                </h2>
                {data.jobs.map((job) => (
                  <div key={job.jobs_no}>
                    <Accordion className="col-12">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="d-flex justify-content-center">
                          <h3>
                            {ReactHtmlParser(
                              currentLanguageCode === "tr"
                                ? job.jobs_name_tr
                                : job.jobs_name_en
                            )}
                          </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            {ReactHtmlParser(
                              currentLanguageCode === "tr"
                                ? job.jobs_desc_tr
                                : job.jobs_desc_en
                            )}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

Career.propTypes = {
  strings: PropTypes.object.isRequired,
};

export default connect()(multilanguage(Career));
