import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import Faqs from "./Pages/Faqs";
import "react-toastify/dist/ReactToastify.css";

import Home from "./Pages/Home"
import AboutUs from "./Pages/AboutUs";
import Career from "./Pages/Career";
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import NotFound from "./Pages/NotFound";
import UniversalPage from "./Pages/UniversalPage";
import "./App.css"




function App(props) {
  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./Data/DataEn.json"),
        tr: require("./Data/DataTr.json"),
      },
    })
  );
  return (
    <Router basename="/">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/international-trade" element={<UniversalPage />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/services" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Router>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
