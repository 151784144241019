import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import axios from "axios";

const CountryList = ({ strings, currentLanguageCode }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://idare.konakglobal.com/ticaret");
      setData(response.data[0]); 
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="container">
      <div className="row map-li">
        {data.dunya && data.dunya.map((continent, index) => (
          <div className="col-md-4 col-sm-8 col-md-4" key={index}>
            <h5 className={`widget-title-${continent.title_inner.toLowerCase()} pt-3`}>{continent.title_inner}</h5>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="widget widget-links">
                <ul>
                  {continent.countries.map((country, countryIndex) => (
                    <li key={countryIndex}>
                      <p>{country}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryList;
