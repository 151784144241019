import React, {useState, useEffect} from 'react'
import Header from '../Wrappers/Header'
import Footer from '../Wrappers/Footer'
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";

const Layout = ({children, strings,path}) => {


  const [headerClassName, setHeaderClassName] = useState("navbar navbar-default navbar-fixed navbar-transparent white bootsnav on no-full")
  
/*   useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setHeaderClassName('navbar navbar-default navbar-fixed white bootsnav on no-full');
      } else {
        setHeaderClassName('navbar navbar-default navbar-fixed navbar-transparent white bootsnav on no-full');
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); */

  return (
    <>
    <Header headerClassName={headerClassName}/>
    {children}
    {path == "/" ? null :  <Footer/>}
   
    </>
    
  )
}
Layout.propTypes = {
    children: PropTypes.any,
    strings: PropTypes.object,
  };
  
  export default multilanguage(Layout);

